import React from 'react';
import Bio from '../components/Bio';

export default class Landing extends React.Component {
    render() {
        return (
            <Bio />
        );
    }
}
